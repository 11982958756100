const CheckoutStepsConfig = {
	signin() {
		return {
			title: 'Tus datos',
			editable: false,
		}
	},
	discount() {
		return {
			title: 'Aplicá descuentos',
			editable: true,
		}
	},
	delivery() {
		return {
			title: this.state == 'done' ? 'Tu forma de entrega' : 'Elige cómo recibir tu compra',
			editable: true,
		}
	},
	payment() {
		return {
			title: this.state == 'done' ? 'Tu forma de pago' : 'Elige la Forma de Pago',
			editable: true,
		}
	},
	contact() {
		return {
			title: 'Contacto y Facturación',
			editable: true,
		}
	},
	confirm() {
		return {
			title: this.$srv('simpleOrder') ? 'Confirma el pedido' : 'Realizá el pago',
			editable: false,
		}
	},
}

export default CheckoutStepsConfig

